import "react-datepicker/dist/react-datepicker.css"
import ActionsMenu from '../../Shared/Components/ActionsMenu'
import AlertDismissible from '../../Shared/Components/AlertDismissible'
import Breadcrumbs from '../../Shared/Components/Breadcrumbs'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Layout from '../../Shared/Layout/LayoutAdmin'
import PaginationList from '../../Shared/Components/PaginationList'
import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import es from 'date-fns/locale/es'
import { Inertia } from '@inertiajs/inertia'
import { Link, usePage } from '@inertiajs/inertia-react'
import { hasPermission } from '../../utils/Permissions'
import { registerLocale } from "react-datepicker"
registerLocale('es', es)

const List = ({ items, companies, years }) => {
    const { flash } = usePage().props
    const data = items.data

    const query = new URL(window.location).searchParams
    const [company, setCompany] = useState(query.get("company") || '')
    const [fortnight, setFortnight] = useState(query.get("fortnight") || '')
    const [year, setYear] = useState(query.get("year") || '')

    useEffect(() => {
        setCompany(query.get("company") || '')
        setFortnight(query.get("fortnight") || '')
        setYear(query.get("year") || '')
    }, [])

    function handleChangeCompany(e) {
        setCompany(e.target.value)
    }
    function handleChangeFortnight(e) {
        setFortnight(e.target.value)
    }

    function handleChangeYear(e) {
        setYear(e.target.value)
    }

    function search() { // search
        Inertia.get(route('portfolio_management.list'),
            {
                company: company,
                fortnight: fortnight,
                year: year,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }


    return (
        <div>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/quotes/applications" />
            </div>
            <Col className="mb-2 px-1 d-flex justify-content-between">
                <h2>Listado de Layouts Generados</h2>
            </Col>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}

            <Row className="mb-4">
                <Col sm={3}>
                    <Form.Label className="mb-0">Empresa</Form.Label>
                    <Form.Select className="form-control" defaultValue={company} onChange={handleChangeCompany} size="sm">
                        <option value={''}></option>
                        {companies.map((company, index) => {
                            return <option key={index} value={company.id}>{company.name} - {company.location.nombre}</option>
                        })}
                    </Form.Select>
                </Col>
                <Col sm={3}>
                    <Form.Label className="mb-0">Quincena</Form.Label>
                    <Form.Select className="form-control" defaultValue={fortnight} onChange={handleChangeFortnight} size="sm">
                        <option value={''}></option>
                        <option value={1}>1era Quincena - 15 Enero</option>
                        <option value={2}>2ª Quincena - 01 Febrero</option>
                        <option value={3}>3ª Quincena - 15 Febrero</option>
                        <option value={4}>4ª Quincena - 01 Marzo</option>
                        <option value={5}>5ª Quincena - 15 Marzo</option>
                        <option value={6}>6ª Quincena - 01 Abril</option>
                        <option value={7}>7ª Quincena - 15 Abril</option>
                        <option value={8}>8ª Quincena - 01 Mayo</option>
                        <option value={9}>9ª Quincena - 15 Mayo</option>
                        <option value={10}>10ª Quincena - 01 Junio</option>
                        <option value={11}>11ª Quincena - 15 Junio</option>
                        <option value={12}>12ª Quincena - 01 Julio</option>
                        <option value={13}>13ª Quincena - 15 Julio</option>
                        <option value={14}>14ª Quincena - 01 Agosto</option>
                        <option value={15}>15ª Quincena - 15 Agosto</option>
                        <option value={16}>16ª Quincena - 01 Septiembre</option>
                        <option value={17}>17ª Quincena - 15 Septiembre</option>
                        <option value={18}>18ª Quincena - 01 Octubre</option>
                        <option value={19}>19ª Quincena - 15 Octubre</option>
                        <option value={20}>20ª Quincena - 01 Noviembre</option>
                        <option value={21}>21ª Quincena - 15 Noviembre</option>
                        <option value={22}>22ª Quincena - 01 Diciembre</option>
                        <option value={23}>23ª Quincena - 15 Diciembre</option>
                        <option value={24}>24ª Quincena - 01 Enero</option>
                    </Form.Select>
                </Col>
                <Col sm={3}>
                    <Form.Label className="mb-0">Año</Form.Label>
                    <Form.Select className="form-control" defaultValue={year} onChange={handleChangeYear} size="sm">
                        <option value={''}></option>
                        {years.map((year, index) => {
                            return <option key={index} value={year.year}>{year.year}</option>
                        })}
                    </Form.Select>
                </Col>
                <Col sm={2}>
                    <Form.Label className="mb-0 d-block">&nbsp;</Form.Label>
                    <Button onClick={search} variant="primary" className='me-3 btn-sm' type="submit" >Filtrar</Button>
                </Col>
            </Row>

            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-6 fw-bold text-gray-400 border-bottom-0">
                                    <th className="text-start">Empresa</th>
                                    <th className="text-start">Quincena</th>
                                    <th className="text-start">Año</th>
                                    <th className="text-start">Tipo</th>
                                    <th className="text-start">Vacío</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="4"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.company.name}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.fortnight}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.year}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.type.toUpperCase()}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{(item.is_empty == 0) ? 'No' : 'Si'}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <PaginationList links={items.links} />
                </div>
            </div>
        </div>
    )
}

List.layout = page => <Layout children={page} title="Layouts Generados" />

export default List
