import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { usePage } from '@inertiajs/inertia-react';
import './excel.css'

const PreviewExcel = forwardRef((props, ref) => {
    const { flash, auth } = usePage().props;
    const [data, setData] = useState([]);

    const [dialog, setDialog] = useState(false);
    const closeDialog = () => {
        setDialog(false);
    };
    const showDialog = () => setDialog(true);

    useImperativeHandle(ref, () => {
        return {
            openDialog(data) {
                setData(data);
                showDialog();
            },
        }
    });

    const tableStyle = {
        color: "white",
        backgroundColor: "DodgerBlue",
        padding: "10px",
        fontFamily: "Arial"
    };

    return <>
        {/* <!--begin:Appointments Modal--> */}
        <Modal show={dialog} onHide={closeDialog} scrollable={true} size="xl" id="dialog">
            <Modal.Header closeButton className="py-4">
                <Modal.Title>
                    Prevista Excel
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: data }}></div>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={closeDialog} className="btn-sm" variant="secondary">Cerrar</Button>
            </Modal.Footer>
        </Modal >
        {/* <!--end:Appointments Modal--> */}
    </>;
})

export default PreviewExcel
