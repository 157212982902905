import React from 'react'
import { hasPermission } from '../../utils/Permissions';

const pathname = window.location.pathname;

function searchPath(route) {
    return pathname.search(route);
};


export default function LayoutAdminSidebar({ auth }) {
    return (
        <>
            {/* <!--begin::Sidebar--> */}
            <div id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
                {/* <!--begin::Logo--> */}
                <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
                    {/* <!--begin::Logo image--> */}
                    <a href="/admin">
                        <img alt="Logo" src="/images/logo.png" className="h-25px app-sidebar-logo-default" />
                        <img alt="Logo" src="/images/logo-small.png" className="h-20px app-sidebar-logo-minimize" />
                    </a>
                    {/* <!--end::Logo image--> */}
                    {/* <!--begin::Sidebar toggle--> */}
                    <div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
                        {/* <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg--> */}
                        <span className="svg-icon svg-icon-2 rotate-180">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
                                <path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
                            </svg>
                        </span>
                        {/* <!--end::Svg Icon--> */}
                    </div>
                    {/* <!--end::Sidebar toggle--> */}
                </div>
                {/* <!--end::Logo--> */}
                {/* <!--begin::sidebar menu--> */}
                <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                    {/* <!--begin::Menu wrapper--> */}
                    <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
                        {/* <!--begin::Menu--> */}
                        <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                            {/* <!--begin:Menu item--> */}
                            {hasPermission('master_data') && (
                                <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + (searchPath('master_data') > -1 ? 'active hover show' : '')}>
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M20 21H11C10.4 21 10 20.6 10 20V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V20C21 20.6 20.6 21 20 21Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <span className="menu-title">Datos Maestros</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    <div className="menu-sub menu-sub-accordion">
                                        <div className="menu-item">
                                            <a className={"menu-link " + (searchPath('master_data/departments') > -1 ? 'active' : '')} href={route('departments.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Departamentos/Puestos</span>
                                            </a>

                                        </div>

                                        <div className="menu-item">
                                            <a className={"menu-link " + (searchPath('master_data/locations') > -1 ? 'active' : '')} href={route('locations.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Estados/Oficinas<br />/Empresas/Emisores</span>
                                            </a>
                                        </div>
                                        {hasPermission('quotes.insurance.investment_fund.history') && (
                                            <div className="menu-item">
                                                <a className={"menu-link " + (searchPath('master_data/investment_fund_percentage') > -1 ? 'active' : '')} href={route('investment_fund_percentage.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Porcentaje de Fondo de Inversión</span>
                                                </a>
                                            </div>
                                        )}
                                        <div className="menu-item">
                                            <a className={"menu-link " + (searchPath('master_data/policy_types') > -1 ? 'active' : '')} href={route('policyTypes.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Tipo de Pólizas</span>
                                            </a>
                                        </div>

                                        <div className="menu-item">
                                            <a className={"menu-link " + (searchPath('master_data/product_types') > -1 ? 'active' : '')} href={route('productTypes.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Tipo de Productos</span>
                                            </a>
                                        </div>

                                        <div className="menu-item">
                                            <a className={"menu-link " + (searchPath('master_data/taxregimes') > -1 ? 'active' : '')} href={route('taxregimes.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Regímenes Fiscales</span>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            )}
                            {/* <!--end:Menu item--> */}
                            {/* <!--begin:Menu item--> */}
                            {hasPermission('corporate_structure') && (
                                <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + (searchPath('corporate_structure') > -1 ? 'active hover show' : '')}>
                                    {/* <!--begin:Menu link--> */}
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"></path>
                                                    <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {/* <!--end::Svg Icon--> */}
                                        </span>
                                        <span className="menu-title">Estructura corporativa</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link--> */}
                                    {/* <!--begin:Menu sub--> */}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item--> */}
                                        {hasPermission('corporate_structure.prospects.view') && (
                                            <div className="menu-item">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('corporate_structure/prospects') > -1 ? 'active' : '')} href={route('prospects.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Prospectos</span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                        )}
                                        {/* <!--end:Menu item--> */}
                                        {/* <!--begin:Menu item--> */}
                                        {hasPermission('corporate_structure.employees.view') && (
                                            <div className="menu-item">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('corporate_structure/employees') > -1 ? 'active' : '')} href={route('employees.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Empleados</span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                        )}
                                        {/* <!--end:Menu item--> */}
                                        {/* <!--begin:Menu item--> */}
                                        {hasPermission('corporate_structure.advisers.view') && (
                                            <div className="menu-item">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('corporate_structure/advisers') > -1 ? 'active' : '')} href={route('advisers.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Asesores</span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                        )}
                                        {/* <!--end:Menu item--> */}
                                        {/* <!--begin:Menu item--> */}
                                        {hasPermission('roles') && (
                                            <div className="menu-item">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('corporate_structure/roles') > -1 ? 'active' : '')} href={route('roles.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Roles</span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                        )}
                                        {/* <!--end:Menu item--> */}
                                    </div>
                                    {/* <!--end:Menu link--> */}
                                </div>
                            )}
                            {/* <!--end:Menu item--> */}
                            {/* <!--begin:Menu item--> */}
                            {hasPermission('quotes') && (
                                <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + (searchPath('quotes') > -1 && searchPath('quotes/commissions') == -1 ? 'active hover show' : '')}>
                                    {/* <!--begin:Menu link--> */}
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M22 7H2V11H22V7Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {/* <!--end::Svg Icon--> */}
                                        </span>
                                        <span className="menu-title">Cotizaciones</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link--> */}
                                    {/* <!--begin:Menu sub--> */}
                                    <div className="menu-sub menu-sub-accordion">
                                        {hasPermission('quotes.applications.policy.view_any') && (<>
                                            {/* <!--begin:Menu item--> */}
                                            <div className="menu-item ">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('quotes/applications') > -1 ? 'active' : '')} href={route('insurance.application.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Solicitudes</span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                            {/* <!--end:Menu item--> */}
                                        </>)}
                                        {/* <!--begin:Menu item--> */}
                                        {hasPermission('quotes.insurance.policy.view') && (
                                            <div className="menu-item">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('quotes/insurance') > -1 ? 'active' : '')} href={route('insurance.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Pólizas</span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                        )}
                                        {/* <!--end:Menu item--> */}
                                        {/* <!--begin:Menu item--> */}
                                        {hasPermission('quotes.appointments') && (
                                            <div className="menu-item">
                                                {/* <!--begin:Menu link--> */}
                                                <a className={"menu-link " + (searchPath('quotes/appointments') > -1 ? 'active' : '')} href={route('appointments.list')}>
                                                    <span className="menu-bullet">
                                                        <span className="bullet bullet-dot"></span>
                                                    </span>
                                                    <span className="menu-title">Citas Programadas
                                                        <span className="menu-badge">
                                                            <span className="badge badge-light-danger badge-circle fw-bold fs-7">{auth.user.notifications}</span>
                                                        </span>
                                                    </span>
                                                </a>
                                                {/* <!--end:Menu link--> */}
                                            </div>
                                        )}
                                        {/* <!--end:Menu item--> */}
                                    </div>
                                    {/* <!--end:Menu link--> */}
                                </div>
                            )}
                            {/* <!--end:Menu item--> */}
                            {/* <!--begin:Menu item--> */}
                            {(hasPermission('quotes.commissions.view') || hasPermission('quotes.commissions.view_any')) && (
                                <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + (searchPath('quotes/commissions') > -1 ? 'active hover show' : '')}>
                                    {/* <!--begin:Menu link--> */}
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            {/* <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg--> */}
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.0021 10.9128V3.01281C13.0021 2.41281 13.5021 1.91281 14.1021 2.01281C16.1021 2.21281 17.9021 3.11284 19.3021 4.61284C20.7021 6.01284 21.6021 7.91285 21.9021 9.81285C22.0021 10.4129 21.5021 10.9128 20.9021 10.9128H13.0021Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M11.0021 13.7128V4.91283C11.0021 4.31283 10.5021 3.81283 9.90208 3.91283C5.40208 4.51283 1.90209 8.41284 2.00209 13.1128C2.10209 18.0128 6.40208 22.0128 11.3021 21.9128C13.1021 21.8128 14.7021 21.3128 16.0021 20.4128C16.5021 20.1128 16.6021 19.3128 16.1021 18.9128L11.0021 13.7128Z" fill="currentColor"></path>
                                                    <path opacity="0.3" d="M21.9021 14.0128C21.7021 15.6128 21.1021 17.1128 20.1021 18.4128C19.7021 18.9128 19.0021 18.9128 18.6021 18.5128L13.0021 12.9128H20.9021C21.5021 12.9128 22.0021 13.4128 21.9021 14.0128Z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            {/* <!--end::Svg Icon--> */}
                                        </span>
                                        <span className="menu-title">Comisiones</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link--> */}
                                    {/* <!--begin:Menu sub--> */}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item--> */}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link--> */}
                                            <a className={"menu-link " + (searchPath('quotes/commissions') > -1 ? 'active' : '')} href={route('commissions.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Financiamiento</span>
                                            </a>
                                            {/* <!--end:Menu link--> */}
                                        </div>
                                        {/* <!--end:Menu item--> */}
                                    </div>
                                    {/* <!--end:Menu link--> */}
                                </div>
                            )}
                            {(hasPermission('quotes.commissions.view') || hasPermission('quotes.commissions.view_any')) && (
                                <div data-kt-menu-trigger="click" className={"menu-item menu-accordion " + (searchPath('portfolio_management') > -1 ? 'active hover show' : '')}>
                                    {/* <!--begin:Menu link--> */}
                                    <span className="menu-link">
                                        <span className="menu-icon">
                                            <span className="svg-icon svg-icon-2">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="currentColor"></path><path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="currentColor"></path></svg>
                                            </span>
                                        </span>
                                        <span className="menu-title">Administración de Cartera</span>
                                        <span className="menu-arrow"></span>
                                    </span>
                                    {/* <!--end:Menu link--> */}
                                    {/* <!--begin:Menu sub--> */}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item--> */}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link--> */}
                                            <a className={"menu-link " + (searchPath('portfolio_management/list') > -1 ? 'active' : '')} href={route('portfolio_management.list')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Layouts Generados</span>
                                            </a>
                                            {/* <!--end:Menu link--> */}
                                        </div>
                                        {/* <!--end:Menu item--> */}
                                    </div>
                                    {/* <!--end:Menu link--> */}
                                    {/* <!--begin:Menu sub--> */}
                                    <div className="menu-sub menu-sub-accordion">
                                        {/* <!--begin:Menu item--> */}
                                        <div className="menu-item">
                                            {/* <!--begin:Menu link--> */}
                                            <a className={"menu-link " + (searchPath('portfolio_management/add') > -1 ? 'active' : '')} href={route('portfolio_management.add')}>
                                                <span className="menu-bullet">
                                                    <span className="bullet bullet-dot"></span>
                                                </span>
                                                <span className="menu-title">Creación de Layout</span>
                                            </a>
                                            {/* <!--end:Menu link--> */}
                                        </div>
                                        {/* <!--end:Menu item--> */}
                                    </div>
                                    {/* <!--end:Menu link--> */}
                                </div>
                            )}
                        </div>
                        {/* <!--end::Menu--> */}
                    </div>
                    {/* <!--end::Menu wrapper--> */}
                </div>
                {/* <!--end::sidebar menu--> */}
                {/* <!--begin::Footer--> */}
                <div className="d-none app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
                    <a href="https://preview.keenthemes.com/html/metronic/docs" className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title="200+ in-house components and 3rd-party plugins">
                        <span className="btn-label">Docs & Components</span>
                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen005.svg--> */}
                        <span className="svg-icon btn-icon svg-icon-2 m-0">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor" />
                                <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor" />
                                <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor" />
                                <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor" />
                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor" />
                            </svg>
                        </span>
                        {/* <!--end::Svg Icon--> */}
                    </a>
                </div>
                {/* <!--end::Footer--> */}
            </div>
            {/* <!--end::Sidebar--> */}
        </>
    );
}
