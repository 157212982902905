import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Layout from '../../Shared/Layout/LayoutAdmin';
import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import classNames from "classnames";
import es from 'date-fns/locale/es';
import { registerLocale } from "react-datepicker";
import { useForm } from '@inertiajs/inertia-react'
registerLocale('es', es)

const Edit = ({ products, advisers, offices, states, companies, application, hasPolicy }) => {

    const [tabkey, setTabkey] = useState('step1');

    const { data, setData, transform, post, processing, errors } = useForm({

        // ***** STEP1 *****

        application_number: application.application_number,
        status: application.status,
        policy_number: application.policy_number,
        reanalysis: application.reanalysis,

        product_type_id: application.product_type_id,
        adviser_id: application.adviser_id,
        adviser2_id: application.adviser2_id,
        adviser3_id: application.adviser3_id,
        adviser_percentage: application.adviser_percentage,
        adviser2_percentage: application.adviser2_percentage,
        adviser3_percentage: application.adviser3_percentage,
        office_id: application.office_id,
        oii: application.oii,
        contractor_email: application.contractor_email,

        contractor_name: application.contractor_name,
        contractor_last_name: application.contractor_last_name,
        contractor_mother_last_name: application.contractor_mother_last_name,
        contractor_rfc: application.contractor_rfc,
        contractor_phone_number: application.contractor_phone_number,

        contractor_street_number: application.contractor_street_number,
        contractor_neighborhood: application.contractor_neighborhood,
        contractor_city: application.contractor_city,
        contractor_state_id: application.contractor_state_id == null ? '' : application.contractor_state_id,
        contractor_zip_code: application.contractor_zip_code,

        // ***** STEP2 *****

        is_same: application.is_same,
        coverage_name: application.coverage_name,
        coverage_lastname_paternal: application.coverage_lastname_paternal,
        coverage_lastname_maternal: application.coverage_lastname_maternal,

        coverage_rfc: application.coverage_rfc,
        coverage_phone_number: application.coverage_phone_number,
        coverage_birthdate: strToDate(application.coverage_birthdate),
        coverage_email: application.coverage_email,
        coverage_sex: application.coverage_sex,

        coverage_disease: application.coverage_disease,
        coverage_smoke: application.coverage_smoke,

        coverage_street_number: application.coverage_street_number,
        coverage_neighborhood: application.coverage_neighborhood,
        coverage_city: application.coverage_city,
        coverage_state_id: application.coverage_state_id == null ? '' : application.coverage_state_id,
        coverage_zip_code: application.coverage_zip_code,

        // ***** STEP3 *****

        beneficiaries: application.beneficiarios.map(a => { return { ...a, birthdate: strToDate(a.birthdate) } }),

        // ***** STEP4 *****

        application_week: application.application_week,
        application_effective_date: strToDate(application.application_effective_date),
        application_production_date: strToDate(application.application_production_date),
        application_sum_assured: application.application_sum_assured,
        application_biweekly_discount: application.application_biweekly_discount,
        application_company_id: application.application_company_id,
        application_liquid_income: application.application_liquid_income,
        application_comments: application.application_comments,
        application_employee_number: application.application_employee_number == null ? '' : application.application_employee_number,
        application_budget_key_1: application.application_budget_key_1 == null ? '' : application.application_budget_key_1,
        application_budget_key_2: application.application_budget_key_2 == null ? '' : application.application_budget_key_2,
        application_budget_key_3: application.application_budget_key_3 == null ? '' : application.application_budget_key_3,
        application_budget_key_4: application.application_budget_key_4 == null ? '' : application.application_budget_key_4,
        application_budget_key_5: application.application_budget_key_5 == null ? '' : application.application_budget_key_5,
        application_budget_key_6: application.application_budget_key_6 == null ? '' : application.application_budget_key_6,
        application_group: application.application_group == null ? 'federal' : application.application_group,

        // ***** STEP5 *****

        work_name_school: application.work_name_school,
        work_level: application.work_level,
        work_street_number: application.work_street_number,
        work_neighborhood: application.work_neighborhood,
        work_city: application.work_city,
        work_state_id: application.work_state_id,
        work_school_zone: application.work_school_zone,
        work_director_name: application.work_director_name,
        work_shift: application.work_shift,
        work_section_head: application.work_section_head,
        work_supervision: application.work_supervision,
        work_regional_coordination: application.work_regional_coordination,
    });

    function fillInsured(checked) {
        if (checked) {
            setData(data => ({ ...data, 'coverage_name': data.contractor_name }));
            setData(data => ({ ...data, 'coverage_lastname_paternal': data.contractor_last_name }));
            setData(data => ({ ...data, 'coverage_lastname_maternal': data.contractor_mother_last_name }));
            setData(data => ({ ...data, 'coverage_rfc': data.contractor_rfc }));
            setData(data => ({ ...data, 'coverage_phone_number': data.contractor_phone_number }));
            setData(data => ({ ...data, 'coverage_email': data.contractor_email }));
        }
    }

    function addBeneficiary() {
        const beneficiaries = [...data.beneficiaries];

        beneficiaries.push({
            name: '',
            last_name: '',
            mother_last_name: '',
            relationship: '',
            percentage: '',
            birthdate: '',
        })

        setData(data => ({ ...data, 'beneficiaries': beneficiaries }));
    }

    function removeBeneficiary(index) {
        const beneficiaries = [...data.beneficiaries];

        beneficiaries.splice(index, 1);

        setData(data => ({ ...data, 'beneficiaries': beneficiaries }));
    }

    function setBeneficiaryData(property, index, value) {
        const beneficiaries = [...data.beneficiaries];

        beneficiaries[index][property] = value;

        setData(data => ({ ...data, 'beneficiaries': beneficiaries }));
    }

    const weeks = [...Array(52).keys()];

    function submit(e) {
        e.preventDefault()

        let total = data.beneficiaries.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.percentage), 0);
        if (total !== 100) {
            Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "La suma de los porcentajes de los beneficiarios debe ser igual a 100%",
            });
            return;
        }

        if ((data.adviser_id == null || data.adviser_id == '')) {
            Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Debes seleccionar un Asesor o Empleado",
            });
            return;
        }

        let newBeneficiaries = data.beneficiaries.map(a => { return { ...a } });
        newBeneficiaries.forEach((item, index) => {
            item.birthdate = dateToStr(item.birthdate);
        });

        transform(data => ({
            ...data,
            coverage_birthdate: dateToStr(data.coverage_birthdate),
            application_effective_date: dateToStr(data.application_effective_date),
            application_production_date: dateToStr(data.application_production_date),
            beneficiaries: newBeneficiaries,
        }))

        post(route('insurance.application.update', application.id))
    }

    function dateToStr(date) {
        return date == '' ? '' : moment(date).format('YYYY-MM-DD');
    }

    function strToDate(date) {
        if (date == null || date == '' || !moment(date).isValid()) return '';

        return moment(date).toDate();
    }

    return (
        <Row>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/quotes/applications/edit" />
            </div>
            <h2 className="mb-5">Editar Solicitud</h2>

            {Object.keys(errors).length > 0 && <div className="mb-2 text-danger">Revisa todos los campos del formulario</div>}

            <Form onSubmit={submit}>
                <Tabs activeKey={tabkey} onSelect={(k) => setTabkey(k)}>
                    <Tab eventKey="step1" title="Datos Iniciales">

                        <Card>
                            <Container className="p-5">

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Número de folio</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.application_number}
                                            className={classNames({ 'is-invalid': (errors.application_number) ? true : false, })}
                                            onChange={e => setData('application_number', e.target.value)} aria-invalid={errors.application_number ? "true" : "false"}
                                        />
                                        {errors.application_number && <div className="mt-2 text-danger">{errors.application_number}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Status</Form.Label>
                                        {(data.status == 'created' || data.status == 'evaluation') &&
                                            <Form.Select
                                                value={data.status}
                                                className={classNames({ 'is-invalid': errors.status ? true : false })}
                                                disabled={true}
                                            >
                                                <option value="created">Creado</option>
                                                <option value="evaluation">Evaluación</option>
                                            </Form.Select>
                                        }
                                        {(data.status == 'emission') &&
                                            <Form.Select
                                                value={data.status}
                                                onChange={e => setData('status', e.target.value)}
                                                className={classNames({ 'is-invalid': errors.status ? true : false })}
                                                aria-invalid={errors.status ? "true" : "false"}
                                                disabled
                                            >
                                                <option value="emission">Emitido</option>
                                            </Form.Select>
                                        }
                                        {(data.status == 'rejected' && data.oii !== "Con OII") &&
                                            <Form.Select
                                                value={data.status}
                                                onChange={e => setData('status', e.target.value)}
                                                className={classNames({ 'is-invalid': errors.status ? true : false })}
                                                aria-invalid={errors.status ? "true" : "false"}
                                            >
                                                <option value="emission">Emitido</option>
                                                <option value="rejected">Rechazado</option>
                                            </Form.Select>
                                        }
                                        {(data.status == 'rejected' && data.oii === "Con OII") &&
                                            <Form.Select
                                                value={data.status}
                                                onChange={e => setData('status', e.target.value)}
                                                className={classNames({ 'is-invalid': errors.status ? true : false })}
                                                aria-invalid={errors.status ? "true" : "false"}
                                            >
                                                <option value="evaluation">Evaluación</option>
                                                <option value="rejected">Rechazado</option>
                                            </Form.Select>
                                        }
                                        {errors.status && <div className="mt-2 text-danger">{errors.status}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Numero de póliza</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.policy_number}
                                            className={classNames({ 'is-invalid': (errors.policy_number) ? true : false, })}
                                            onChange={e => setData('policy_number', e.target.value)} aria-invalid={errors.policy_number ? "true" : "false"}
                                            disabled={data.status == "emission"}
                                        />
                                        {errors.policy_number && <div className="mt-2 text-danger">{errors.policy_number}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Producto</Form.Label>
                                        <Form.Select disabled={hasPolicy} defaultValue={data.product_type_id} onChange={e => setData('product_type_id', e.target.value)} className={classNames({ 'is-invalid': (errors.product_type_id) ? true : false, })} aria-invalid={errors.product_type_id ? "true" : "false"} >
                                            <option value=""></option>
                                            {products.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.name}</option>;
                                            })}
                                        </Form.Select>
                                        {errors.product_type_id && <div className="mt-2 text-danger">{errors.product_type_id}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Asesor/Empleado</Form.Label>
                                        <Form.Select
                                            disabled={hasPolicy}
                                            value={data.adviser_id == null ? '' : data.adviser_id}
                                            onChange={e => {
                                                setData(data => ({ ...data, 'adviser_id': e.target.value }));
                                            }}
                                            className={classNames({ 'is-invalid': (errors.adviser_id) ? true : false, })}
                                            aria-invalid={errors.adviser_id ? "true" : "false"}
                                        >
                                            <option value=""></option>
                                            {advisers.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({(item.type == 'adviser') ? 'Asesor' : 'Empleado'})</option>;
                                            })}
                                        </Form.Select>
                                        {errors.adviser_id && <div className="mt-2 text-danger">{errors.adviser_id}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Oficina</Form.Label>
                                        <Form.Select disabled={hasPolicy} defaultValue={data.office_id} onChange={e => setData('office_id', e.target.value)} className={classNames({ 'is-invalid': (errors.office_id) ? true : false, })} aria-invalid={errors.office_id ? "true" : "false"} >
                                            <option value=""></option>
                                            {offices.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.office_location_name} - {item.nombre}</option>;
                                            })}
                                        </Form.Select>
                                        {errors.office_id && <div className="mt-2 text-danger">{errors.office_id}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Correo electrónico</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            defaultValue={data.contractor_email}
                                            className={classNames({ 'is-invalid': (errors.contractor_email) ? true : false, })}
                                            onChange={e => setData('contractor_email', e.target.value)} aria-invalid={errors.contractor_email ? "true" : "false"}
                                        />
                                        {errors.contractor_email && <div className="mt-2 text-danger">{errors.contractor_email}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            value={data.contractor_name}
                                            className={classNames({ 'is-invalid': (errors.contractor_name) ? true : false, })}
                                            onChange={e => setData('contractor_name', e.target.value.toUpperCase())} aria-invalid={errors.contractor_name ? "true" : "false"}
                                        />
                                        {errors.contractor_name && <div className="mt-2 text-danger">{errors.contractor_name}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Apellido Paterno</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            value={data.contractor_last_name}
                                            className={classNames({ 'is-invalid': (errors.contractor_last_name) ? true : false, })}
                                            onChange={e => setData('contractor_last_name', e.target.value.toUpperCase())} aria-invalid={errors.contractor_last_name ? "true" : "false"}
                                        />
                                        {errors.contractor_last_name && <div className="mt-2 text-danger">{errors.contractor_last_name}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Apellido Materno</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            value={data.contractor_mother_last_name}
                                            className={classNames({ 'is-invalid': (errors.contractor_mother_last_name) ? true : false, })}
                                            onChange={e => setData('contractor_mother_last_name', e.target.value.toUpperCase())} aria-invalid={errors.contractor_mother_last_name ? "true" : "false"}
                                        />
                                        {errors.contractor_mother_last_name && <div className="mt-2 text-danger">{errors.contractor_mother_last_name}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>RFC</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            value={data.contractor_rfc}
                                            className={classNames({ 'is-invalid': (errors.contractor_rfc) ? true : false, })}
                                            onChange={e => setData('contractor_rfc', e.target.value.toUpperCase())} aria-invalid={errors.contractor_rfc ? "true" : "false"}
                                        />
                                        {errors.contractor_rfc && <div className="mt-2 text-danger">{errors.contractor_rfc}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            defaultValue={data.contractor_phone_number}
                                            className={classNames({ 'is-invalid': (errors.contractor_phone_number) ? true : false, })}
                                            onChange={e => setData('contractor_phone_number', e.target.value)} aria-invalid={errors.contractor_phone_number ? "true" : "false"}
                                        />
                                        {errors.contractor_phone_number && <div className="mt-2 text-danger">{errors.contractor_phone_number}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Calle y número</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.contractor_street_number}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Colonia</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.contractor_neighborhood}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Ciudad</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.contractor_city}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Select defaultValue={data.contractor_state_id} disabled>
                                            <option value=""></option>
                                            {states.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.name}</option>;
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Código Postal</Form.Label>
                                        <Form.Control
                                            type="number"
                                            defaultValue={data.contractor_zip_code}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row>

                                <div className="d-flex gap-3">
                                    <Button onClick={() => window.history.back()} variant="secondary" type="button">Regresar</Button>
                                    <Button onClick={() => setTabkey('step2')} variant="primary" type="button">Siguiente</Button>
                                </div>

                            </Container>
                        </Card>

                    </Tab>
                    <Tab eventKey="step2" title="Asesor">

                        <Card>
                            <Container className="p-5">

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Asesor</Form.Label>
                                        <Form.Select
                                            disabled={hasPolicy}
                                            value={data.adviser_id == null ? '' : data.adviser_id}
                                            onChange={e => {
                                                setData(data => ({ ...data, 'adviser_id': e.target.value }));
                                            }}
                                            className={classNames({ 'is-invalid': (errors.adviser_id) ? true : false, })}
                                            aria-invalid={errors.adviser_id ? "true" : "false"}
                                        >
                                            <option value=""></option>
                                            {advisers.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({(item.type == 'adviser') ? 'Asesor' : 'Empleado'})</option>;
                                            })}
                                        </Form.Select>
                                        {errors.adviser_id && <div className="mt-2 text-danger">{errors.adviser_id}</div>}
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Porcentaje Solicitud</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            defaultValue={data.adviser_percentage}
                                            className={classNames({ 'is-invalid': (errors.adviser_percentage) ? true : false, })}
                                            onChange={e => setData('adviser_percentage', e.target.value)} aria-invalid={errors.adviser_percentage ? "true" : "false"}
                                        />
                                        {errors.adviser_percentage && <div className="mt-2 text-danger">{errors.adviser_percentage}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Field Trainer</Form.Label>
                                        <Form.Select
                                            disabled={hasPolicy}
                                            value={data.adviser2_id == null ? '' : data.adviser2_id}
                                            onChange={e => {
                                                setData(data => ({ ...data, 'adviser2_id': e.target.value }));
                                            }}
                                            className={classNames({ 'is-invalid': (errors.adviser2_id) ? true : false, })}
                                            aria-invalid={errors.adviser2_id ? "true" : "false"}
                                        >
                                            <option value=""></option>
                                            {advisers.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({(item.type == 'adviser') ? 'Asesor' : 'Empleado'})</option>;
                                            })}
                                        </Form.Select>
                                        {errors.adviser2_id && <div className="mt-2 text-danger">{errors.adviser2_id}</div>}
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Porcentaje Solicitud</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            defaultValue={data.adviser2_percentage}
                                            className={classNames({ 'is-invalid': (errors.adviser2_percentage) ? true : false, })}
                                            onChange={e => setData('adviser2_percentage', e.target.value)} aria-invalid={errors.adviser2_percentage ? "true" : "false"}
                                        />
                                        {errors.adviser2_percentage && <div className="mt-2 text-danger">{errors.adviser2_percentage}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Agente 3</Form.Label>
                                        <Form.Select
                                            disabled={hasPolicy}
                                            value={data.adviser3_id == null ? '' : data.adviser3_id}
                                            onChange={e => {
                                                setData(data => ({ ...data, 'adviser3_id': e.target.value }));
                                            }}
                                            className={classNames({ 'is-invalid': (errors.adviser3_id) ? true : false, })}
                                            aria-invalid={errors.adviser3_id ? "true" : "false"}
                                        >
                                            <option value=""></option>
                                            {advisers.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.user_name} {item.lastname_ap} {item.lastname_am} ({(item.type == 'adviser') ? 'Asesor' : 'Empleado'})</option>;
                                            })}
                                        </Form.Select>
                                        {errors.adviser3_id && <div className="mt-2 text-danger">{errors.adviser3_id}</div>}
                                    </Form.Group>

                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Porcentaje Solicitud</Form.Label>
                                        <Form.Control
                                            disabled={hasPolicy}
                                            type="text"
                                            defaultValue={data.adviser3_percentage}
                                            className={classNames({ 'is-invalid': (errors.adviser3_percentage) ? true : false, })}
                                            onChange={e => setData('adviser3_percentage', e.target.value)} aria-invalid={errors.adviser3_percentage ? "true" : "false"}
                                        />
                                        {errors.adviser3_percentage && <div className="mt-2 text-danger">{errors.adviser3_percentage}</div>}
                                    </Form.Group>
                                </Row>

                                <div className="d-flex gap-3">
                                    <Button onClick={() => setTabkey('step1')} variant="secondary" type="button">Atrás</Button>
                                    <Button onClick={() => setTabkey('step3')} variant="primary" type="button">Siguiente</Button>
                                </div>

                            </Container>
                        </Card>
                    </Tab>
                    <Tab eventKey="step3" title="Asegurados">

                        <Card>
                            <Container className="p-5">

                                <Form.Group className="mb-5">
                                    <Form.Check
                                        disabled={hasPolicy}
                                        inline
                                        name="is_same"
                                        type="checkbox"
                                        label="¿El asegurado es el mismo que el contratante?"
                                        value="1"
                                        id="is_same"
                                        defaultChecked={(data.is_same == 1 ? true : false)}
                                        onChange={e => { setData('is_same', e.target.checked ? 1 : 0); fillInsured(e.target.checked); }}
                                        className={classNames({ 'is-invalid': (errors.is_same) ? true : false, })}
                                        aria-invalid={errors.is_same ? "true" : "false"}
                                    />
                                </Form.Group>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            disabled={data.is_same == 1 || hasPolicy}
                                            type="text"
                                            value={data.coverage_name}
                                            className={classNames({ 'is-invalid': (errors.coverage_name) ? true : false, })}
                                            onChange={e => setData('coverage_name', e.target.value.toUpperCase())} aria-invalid={errors.coverage_name ? "true" : "false"}
                                        />
                                        {errors.coverage_name && <div className="mt-2 text-danger">{errors.coverage_name}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Apellido Paterno</Form.Label>
                                        <Form.Control
                                            disabled={data.is_same == 1 || hasPolicy}
                                            type="text"
                                            value={data.coverage_lastname_paternal}
                                            className={classNames({ 'is-invalid': (errors.coverage_lastname_paternal) ? true : false, })}
                                            onChange={e => setData('coverage_lastname_paternal', e.target.value.toUpperCase())} aria-invalid={errors.coverage_lastname_paternal ? "true" : "false"}
                                        />
                                        {errors.coverage_lastname_paternal && <div className="mt-2 text-danger">{errors.coverage_lastname_paternal}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Apellido Materno</Form.Label>
                                        <Form.Control
                                            disabled={data.is_same == 1 || hasPolicy}
                                            type="text"
                                            value={data.coverage_lastname_maternal}
                                            className={classNames({ 'is-invalid': (errors.coverage_lastname_maternal) ? true : false, })}
                                            onChange={e => setData('coverage_lastname_maternal', e.target.value.toUpperCase())} aria-invalid={errors.coverage_lastname_maternal ? "true" : "false"}
                                        />
                                        {errors.coverage_lastname_maternal && <div className="mt-2 text-danger">{errors.coverage_lastname_maternal}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>RFC</Form.Label>
                                        <Form.Control
                                            disabled={data.is_same == 1 || hasPolicy}
                                            type="text"
                                            value={data.coverage_rfc}
                                            className={classNames({ 'is-invalid': (errors.coverage_rfc) ? true : false, })}
                                            onChange={e => setData('coverage_rfc', e.target.value.toUpperCase())} aria-invalid={errors.coverage_rfc ? "true" : "false"}
                                        />
                                        {errors.coverage_rfc && <div className="mt-2 text-danger">{errors.coverage_rfc}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control
                                            disabled={data.is_same == 1 || hasPolicy}
                                            type="text"
                                            value={data.coverage_phone_number}
                                            className={classNames({ 'is-invalid': (errors.coverage_phone_number) ? true : false, })}
                                            onChange={e => setData('coverage_phone_number', e.target.value)} aria-invalid={errors.coverage_phone_number ? "true" : "false"}
                                        />
                                        {errors.coverage_phone_number && <div className="mt-2 text-danger">{errors.coverage_phone_number}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Fecha de Nacimiento</Form.Label>
                                        <DatePicker className="form-control" locale="es" selected={data.coverage_birthdate} onChange={(date) => setData('coverage_birthdate', date)} dateFormat="dd/MM/yyyy" />
                                        {errors.coverage_birthdate && <div className="mt-2 text-danger">{errors.coverage_birthdate}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Correo electrónico</Form.Label>
                                        <Form.Control
                                            disabled={data.is_same == 1 || hasPolicy}
                                            type="text"
                                            value={data.coverage_email}
                                            className={classNames({ 'is-invalid': (errors.coverage_email) ? true : false, })}
                                            onChange={e => setData('coverage_email', e.target.value)} aria-invalid={errors.coverage_email ? "true" : "false"}
                                        />
                                        {errors.coverage_email && <div className="mt-2 text-danger">{errors.coverage_email}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Sexo</Form.Label>
                                        <Form.Select className="form-control" defaultValue={data.coverage_sex} onChange={e => setData('coverage_sex', e.target.value)}>
                                            <option value={'Hombre'}>Hombre</option>
                                            <option value={'Mujer'}>Mujer</option>
                                        </Form.Select>
                                        {errors.coverage_sex && <div className="mt-2 text-danger">{errors.coverage_sex}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={3} className="mb-3" controlId="formAdd">
                                        <Form.Label>Enfermedad</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.coverage_disease}
                                            className={classNames({ 'is-invalid': (errors.coverage_disease) ? true : false, })}
                                            onChange={e => setData('coverage_disease', e.target.value.toUpperCase())} aria-invalid={errors.coverage_disease ? "true" : "false"}
                                        />
                                        {errors.coverage_disease && <div className="mt-2 text-danger">{errors.coverage_disease}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={3} controlId="formGridVeritas">
                                        <Form.Label className="mb-3">¿Fumador?</Form.Label>
                                        <InputGroup>
                                            <Form.Check
                                                inline
                                                name="coverage_smoke"
                                                type="radio"
                                                label="Si"
                                                value="1"
                                                id="coverage_smoke_1"
                                                defaultChecked={(data.coverage_smoke == 1 ? true : false)}
                                                onChange={e => setData('coverage_smoke', 1)} className={classNames({ 'is-invalid': (errors.coverage_smoke) ? true : false, })} aria-invalid={errors.coverage_smoke ? "true" : "false"} />
                                            <Form.Check
                                                inline
                                                name="coverage_smoke"
                                                type="radio"
                                                label="No"
                                                value="0"
                                                id="coverage_smoke_0"
                                                defaultChecked={(data.coverage_smoke == 0 ? true : false)}
                                                onChange={e => setData('coverage_smoke', 0)} className={classNames({ 'is-invalid': (errors.coverage_smoke) ? true : false, })} aria-invalid={errors.coverage_smoke ? "true" : "false"} />
                                        </InputGroup>
                                        {errors.coverage_smoke && <div className="mt-2 text-danger">{errors.coverage_smoke}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Calle y número</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.coverage_street_number}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Colonia</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.coverage_neighborhood}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Ciudad</Form.Label>
                                        <Form.Control
                                            type="text"
                                            defaultValue={data.coverage_city}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Select className="form-control" defaultValue={data.coverage_state_id} onChange={e => setData('coverage_state_id', e.target.value)}>
                                            <option value=""></option>
                                            {states.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.name}</option>;
                                            })}
                                        </Form.Select>
                                        {errors.coverage_state_id && <div className="mt-2 text-danger">{errors.coverage_state_id}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Código Postal</Form.Label>
                                        <Form.Control
                                            type="number"
                                            defaultValue={data.coverage_zip_code}
                                            disabled
                                        />
                                    </Form.Group>
                                </Row>

                                <div className="d-flex gap-3">
                                    <Button onClick={() => setTabkey('step2')} variant="secondary" type="button">Atrás</Button>
                                    <Button onClick={() => setTabkey('step4')} variant="primary" type="button">Siguiente</Button>
                                </div>

                            </Container>
                        </Card>

                    </Tab>
                    <Tab eventKey="step4" title="Beneficiarios">

                        <Card>
                            <Container className="p-5">

                                <div className="mb-3">
                                    <Button size="sm" onClick={() => addBeneficiary()} variant="primary" type="button">Agregar</Button>
                                </div>

                                <br />

                                {errors.beneficiaries && <div className="mt-2 text-danger">{errors.beneficiaries}</div>}

                                <div className="table-responsive">
                                    <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                                        <thead>
                                            <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                                <th className="min-w-175px text-start">Nombre</th>
                                                <th className="min-w-175px text-start">Apellido Paterno</th>
                                                <th className="min-w-175px text-start">Apellido Materno</th>
                                                <th className="min-w-175px text-start">Parentesco</th>
                                                <th className="min-w-175px text-start">Porcentaje</th>
                                                <th className="min-w-175px text-start">Fecha de Nacimiento</th>
                                                <th className="w-50px text-end"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.beneficiaries.length === 0 && (
                                                <tr>
                                                    <td
                                                        className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                                        colSpan="7"
                                                    >
                                                        No hay datos.
                                                    </td>
                                                </tr>
                                            )}
                                            {data.beneficiaries.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                            <Form.Control
                                                                type="text"
                                                                value={item.name}
                                                                placeholder="..."
                                                                onChange={e => setBeneficiaryData('name', index, e.target.value.toUpperCase())}
                                                                className={classNames({ 'is-invalid': errors[`beneficiaries.${index}.name`] ? true : false, })}
                                                                aria-invalid={errors[`beneficiaries.${index}.name`] ? "true" : "false"}
                                                            />
                                                            {errors[`beneficiaries.${index}.name`] && <div className="mt-2 text-danger">{errors[`beneficiaries.${index}.name`]}</div>}
                                                        </td>
                                                        <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                            <Form.Control
                                                                type="text"
                                                                value={item.last_name}
                                                                placeholder="..."
                                                                onChange={e => setBeneficiaryData('last_name', index, e.target.value.toUpperCase())}
                                                                className={classNames({ 'is-invalid': errors[`beneficiaries.${index}.last_name`] ? true : false, })}
                                                                aria-invalid={errors[`beneficiaries.${index}.last_name`] ? "true" : "false"}
                                                            />
                                                            {errors[`beneficiaries.${index}.last_name`] && <div className="mt-2 text-danger">{errors[`beneficiaries.${index}.last_name`]}</div>}
                                                        </td>
                                                        <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                            <Form.Control
                                                                type="text"
                                                                value={item.mother_last_name}
                                                                placeholder="..."
                                                                onChange={e => setBeneficiaryData('mother_last_name', index, e.target.value.toUpperCase())}
                                                                className={classNames({ 'is-invalid': errors[`beneficiaries.${index}.mother_last_name`] ? true : false, })}
                                                                aria-invalid={errors[`beneficiaries.${index}.mother_last_name`] ? "true" : "false"}
                                                            />
                                                            {errors[`beneficiaries.${index}.mother_last_name`] && <div className="mt-2 text-danger">{errors[`beneficiaries.${index}.mother_last_name`]}</div>}
                                                        </td>
                                                        <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                            <Form.Control
                                                                type="text"
                                                                value={item.relationship}
                                                                placeholder="Padre"
                                                                onChange={e => setBeneficiaryData('relationship', index, e.target.value.toUpperCase())}
                                                                className={classNames({ 'is-invalid': errors[`beneficiaries.${index}.relationship`] ? true : false, })}
                                                                aria-invalid={errors[`beneficiaries.${index}.relationship`] ? "true" : "false"}
                                                            />
                                                            {errors[`beneficiaries.${index}.relationship`] && <div className="mt-2 text-danger">{errors[`beneficiaries.${index}.relationship`]}</div>}
                                                        </td>
                                                        <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                            <Form.Control
                                                                type="number"
                                                                value={item.percentage}
                                                                placeholder="50"
                                                                onChange={e => setBeneficiaryData('percentage', index, e.target.value)}
                                                                className={classNames({ 'is-invalid': errors[`beneficiaries.${index}.percentage`] ? true : false, })}
                                                                aria-invalid={errors[`beneficiaries.${index}.percentage`] ? "true" : "false"}
                                                            />
                                                            {errors[`beneficiaries.${index}.percentage`] && <div className="mt-2 text-danger">{errors[`beneficiaries.${index}.percentage`]}</div>}
                                                        </td>
                                                        <td className="text-gray-800 fw-bold mb-1 fs-6">
                                                            <DatePicker
                                                                className="form-control" locale="es"
                                                                selected={item.birthdate}
                                                                onChange={(date) => setBeneficiaryData('birthdate', index, date)}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                            {errors[`beneficiaries.${index}.birthdate`] && <div className="mt-2 text-danger">{errors[`beneficiaries.${index}.birthdate`]}</div>}
                                                        </td>
                                                        <td>
                                                            <Button size="sm" onClick={() => removeBeneficiary(index)} variant="link" className='text-danger' type="button">Eliminar</Button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <br /><br />

                                <div className="d-flex gap-3">
                                    <Button onClick={() => setTabkey('step3')} variant="secondary" type="button">Atrás</Button>
                                    <Button onClick={() => setTabkey('step5')} variant="primary" type="button">Siguiente</Button>
                                </div>

                            </Container>
                        </Card>

                    </Tab>
                    <Tab eventKey="step5" title="Solicitud">

                        <Card>
                            <Container className="p-5">

                                <Row className="mb-3">
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Semana</Form.Label>
                                            <Form.Select
                                                className="form-control"
                                                value={data.application_week}
                                                onChange={e => setData('application_week', e.target.value)} aria-invalid={errors.application_week ? "true" : "false"}
                                            >
                                                <option key='all' value={''}></option>
                                                {weeks.length > 0 && (
                                                    weeks.map((item, index) => {
                                                        return <option key={index} value={item + 1}>{item + 1}</option>;
                                                    })
                                                )}
                                            </Form.Select>
                                            {errors.application_week && <div className="mt-2 text-danger">{errors.application_week}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Fecha de Vigencia</Form.Label>
                                            <DatePicker
                                                disabled={hasPolicy}
                                                className="form-control" locale="es"
                                                selected={data.application_effective_date}
                                                onChange={(date) => setData('application_effective_date', date)}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            {errors.application_effective_date && <div className="mt-2 text-danger">{errors.application_effective_date}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Fecha de primer descuento</Form.Label>
                                            <DatePicker
                                                className="form-control" locale="es"
                                                selected={data.application_production_date}
                                                onChange={(date) => setData('application_production_date', date)}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                            {errors.application_production_date && <div className="mt-2 text-danger">{errors.application_production_date}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Suma Asegurada</Form.Label>
                                            <Form.Control
                                                disabled={hasPolicy}
                                                type="number"
                                                defaultValue={data.application_sum_assured}
                                                className={classNames({ 'is-invalid': (errors.application_sum_assured) ? true : false, })}
                                                onChange={e => setData('application_sum_assured', e.target.value)} aria-invalid={errors.application_sum_assured ? "true" : "false"}
                                            />
                                            {errors.application_sum_assured && <div className="mt-2 text-danger">{errors.application_sum_assured}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Inversión quincenal</Form.Label>
                                            <Form.Control
                                                disabled={hasPolicy}
                                                type="number"
                                                defaultValue={data.application_biweekly_discount}
                                                className={classNames({ 'is-invalid': (errors.application_biweekly_discount) ? true : false, })}
                                                onChange={e => setData('application_biweekly_discount', e.target.value)} aria-invalid={errors.application_biweekly_discount ? "true" : "false"}
                                            />
                                            {errors.application_biweekly_discount && <div className="mt-2 text-danger">{errors.application_biweekly_discount}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Empresa</Form.Label>
                                            <Form.Select disabled={hasPolicy} defaultValue={data.application_company_id} onChange={e => setData('application_company_id', e.target.value)} className={classNames({ 'is-invalid': (errors.application_company_id) ? true : false, })} aria-invalid={errors.application_company_id ? "true" : "false"} >
                                                <option value=""></option>
                                                {companies.map((item, index) => {
                                                    return <option key={index} value={item.id}>{item.office_location_name} - {item.name}</option>;
                                                })}
                                            </Form.Select>
                                            {errors.application_company_id && <div className="mt-2 text-danger">{errors.application_company_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Ingreso Líquido</Form.Label>
                                            <Form.Control
                                                type="number"
                                                defaultValue={data.application_liquid_income}
                                                className={classNames({ 'is-invalid': (errors.application_liquid_income) ? true : false, })}
                                                onChange={e => setData('application_liquid_income', e.target.value)} aria-invalid={errors.application_liquid_income ? "true" : "false"}
                                            />
                                            {errors.application_liquid_income && <div className="mt-2 text-danger">{errors.application_liquid_income}</div>}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={8}>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Número de Empleado</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={data.application_employee_number}
                                                className={classNames({ 'is-invalid': (errors.application_employee_number) ? true : false, })}
                                                onChange={e => setData('application_employee_number', e.target.value.toUpperCase())} aria-invalid={errors.application_employee_number ? "true" : "false"}
                                            />
                                            {errors.application_employee_number && <div className="mt-2 text-danger">{errors.application_employee_number}</div>}
                                        </Form.Group>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formAdd">
                                                    <Form.Label>Clave Presupuestal 1</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={data.application_budget_key_1}
                                                            className={classNames({ 'is-invalid': (errors.application_budget_key_1) ? true : false, })}
                                                            onChange={e => setData('application_budget_key_1', e.target.value.toUpperCase())} aria-invalid={errors.application_budget_key_1 ? "true" : "false"}
                                                        />
                                                    </Col>
                                                    {errors.application_budget_key_1 && <div className="mt-2 text-danger">{errors.application_budget_key_1}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formAdd">
                                                    <Form.Label>Clave Presupuestal 2</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={data.application_budget_key_2}
                                                            className={classNames({ 'is-invalid': (errors.application_budget_key_2) ? true : false, })}
                                                            onChange={e => setData('application_budget_key_2', e.target.value.toUpperCase())} aria-invalid={errors.application_budget_key_2 ? "true" : "false"}
                                                        />
                                                    </Col>
                                                    {errors.application_budget_key_2 && <div className="mt-2 text-danger">{errors.application_budget_key_2}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formAdd">
                                                    <Form.Label>Clave Presupuestal 3</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={data.application_budget_key_3}
                                                            className={classNames({ 'is-invalid': (errors.application_budget_key_3) ? true : false, })}
                                                            onChange={e => setData('application_budget_key_3', e.target.value.toUpperCase())} aria-invalid={errors.application_budget_key_3 ? "true" : "false"}
                                                        />
                                                    </Col>
                                                    {errors.application_budget_key_3 && <div className="mt-2 text-danger">{errors.application_budget_key_3}</div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formAdd">
                                                    <Form.Label>Clave Presupuestal 4</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={data.application_budget_key_4}
                                                            className={classNames({ 'is-invalid': (errors.application_budget_key_4) ? true : false, })}
                                                            onChange={e => setData('application_budget_key_4', e.target.value.toUpperCase())} aria-invalid={errors.application_budget_key_4 ? "true" : "false"}
                                                        />
                                                    </Col>
                                                    {errors.application_budget_key_4 && <div className="mt-2 text-danger">{errors.application_budget_key_4}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formAdd">
                                                    <Form.Label>Clave Presupuestal 5</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={data.application_budget_key_5}
                                                            className={classNames({ 'is-invalid': (errors.application_budget_key_5) ? true : false, })}
                                                            onChange={e => setData('application_budget_key_5', e.target.value.toUpperCase())} aria-invalid={errors.application_budget_key_5 ? "true" : "false"}
                                                        />
                                                    </Col>
                                                    {errors.application_budget_key_5 && <div className="mt-2 text-danger">{errors.application_budget_key_5}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formAdd">
                                                    <Form.Label>Clave Presupuestal 6</Form.Label>
                                                    <Col>
                                                        <Form.Control
                                                            type="text"
                                                            value={data.application_budget_key_6}
                                                            className={classNames({ 'is-invalid': (errors.application_budget_key_6) ? true : false, })}
                                                            onChange={e => setData('application_budget_key_6', e.target.value.toUpperCase())} aria-invalid={errors.application_budget_key_6 ? "true" : "false"}
                                                        />
                                                    </Col>
                                                    {errors.application_budget_key_6 && <div className="mt-2 text-danger">{errors.application_budget_key_6}</div>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Grupo</Form.Label>
                                            <Form.Select
                                                className="form-control"
                                                value={data.application_group}
                                                onChange={e => setData('application_group', e.target.value)} aria-invalid={errors.application_group ? "true" : "false"}
                                            >
                                                <option key='federal' value={'federal'}>Federal</option>
                                                <option key='regularizado' value={'regularizado'}>Regularizado</option>
                                                <option key='homologado' value={'homologado'}>Homologado</option>
                                            </Form.Select>
                                            {errors.application_group && <div className="mt-2 text-danger">{errors.application_group}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formAdd">
                                            <Form.Label>Observaciones</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                value={data.application_comments}
                                                className={classNames({ 'is-invalid': (errors.application_comments) ? true : false, })}
                                                onChange={e => setData('application_comments', e.target.value.toUpperCase())} aria-invalid={errors.application_comments ? "true" : "false"}
                                            />
                                            {errors.application_comments && <div className="mt-2 text-danger">{errors.application_comments}</div>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className="d-flex gap-3">
                                    <Button onClick={() => setTabkey('step4')} variant="secondary" type="button">Atrás</Button>
                                    <Button onClick={() => setTabkey('step6')} variant="primary" type="button">Siguiente</Button>
                                </div>

                            </Container>
                        </Card>

                    </Tab>
                    <Tab eventKey="step6" title="Datos del centro de trabajo">

                        <Card>
                            <Container className="p-5">

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Nombre de la escuela</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_name_school}
                                            className={classNames({ 'is-invalid': (errors.work_name_school) ? true : false, })}
                                            onChange={e => setData('work_name_school', e.target.value.toUpperCase())} aria-invalid={errors.work_name_school ? "true" : "false"}
                                        />
                                        {errors.work_name_school && <div className="mt-2 text-danger">{errors.work_name_school}</div>}
                                    </Form.Group>

                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Nivel</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_level}
                                            className={classNames({ 'is-invalid': (errors.work_level) ? true : false, })}
                                            onChange={e => setData('work_level', e.target.value.toUpperCase())} aria-invalid={errors.work_level ? "true" : "false"}
                                        />
                                        {errors.work_level && <div className="mt-2 text-danger">{errors.work_level}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Calle y número</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_street_number}
                                            className={classNames({ 'is-invalid': (errors.work_street_number) ? true : false, })}
                                            onChange={e => setData('work_street_number', e.target.value.toUpperCase())} aria-invalid={errors.work_street_number ? "true" : "false"}
                                        />
                                        {errors.work_street_number && <div className="mt-2 text-danger">{errors.work_street_number}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Colonia</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_neighborhood}
                                            className={classNames({ 'is-invalid': (errors.work_neighborhood) ? true : false, })}
                                            onChange={e => setData('work_neighborhood', e.target.value.toUpperCase())} aria-invalid={errors.work_neighborhood ? "true" : "false"}
                                        />
                                        {errors.work_neighborhood && <div className="mt-2 text-danger">{errors.work_neighborhood}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Ciudad</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_city}
                                            className={classNames({ 'is-invalid': (errors.work_city) ? true : false, })}
                                            onChange={e => setData('work_city', e.target.value.toUpperCase())} aria-invalid={errors.work_city ? "true" : "false"}
                                        />
                                        {errors.work_city && <div className="mt-2 text-danger">{errors.work_city}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={2} className="mb-3" controlId="formAdd">
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Select defaultValue={data.work_state_id} onChange={e => setData('work_state_id', e.target.value)} className={classNames({ 'is-invalid': (errors.work_state_id) ? true : false, })} aria-invalid={errors.work_state_id ? "true" : "false"} >
                                            <option value=""></option>
                                            {states.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.name}</option>;
                                            })}
                                        </Form.Select>
                                        {errors.work_state_id && <div className="mt-2 text-danger">{errors.work_state_id}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Zona Escolar</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_school_zone}
                                            className={classNames({ 'is-invalid': (errors.work_school_zone) ? true : false, })}
                                            onChange={e => setData('work_school_zone', e.target.value.toUpperCase())} aria-invalid={errors.work_school_zone ? "true" : "false"}
                                        />
                                        {errors.work_school_zone && <div className="mt-2 text-danger">{errors.work_school_zone}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Nombre Director</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_director_name}
                                            className={classNames({ 'is-invalid': (errors.work_director_name) ? true : false, })}
                                            onChange={e => setData('work_director_name', e.target.value.toUpperCase())} aria-invalid={errors.work_director_name ? "true" : "false"}
                                        />
                                        {errors.work_director_name && <div className="mt-2 text-danger">{errors.work_director_name}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Turno</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_shift}
                                            className={classNames({ 'is-invalid': (errors.work_shift) ? true : false, })}
                                            onChange={e => setData('work_shift', e.target.value.toUpperCase())} aria-invalid={errors.work_shift ? "true" : "false"}
                                        />
                                        {errors.work_shift && <div className="mt-2 text-danger">{errors.work_shift}</div>}
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Jefatura de sección</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_section_head}
                                            className={classNames({ 'is-invalid': (errors.work_section_head) ? true : false, })}
                                            onChange={e => setData('work_section_head', e.target.value.toUpperCase())} aria-invalid={errors.work_section_head ? "true" : "false"}
                                        />
                                        {errors.work_section_head && <div className="mt-2 text-danger">{errors.work_section_head}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Supervisión</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_supervision}
                                            className={classNames({ 'is-invalid': (errors.work_supervision) ? true : false, })}
                                            onChange={e => setData('work_supervision', e.target.value.toUpperCase())} aria-invalid={errors.work_supervision ? "true" : "false"}
                                        />
                                        {errors.work_supervision && <div className="mt-2 text-danger">{errors.work_supervision}</div>}
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} className="mb-3" controlId="formAdd">
                                        <Form.Label>Coordinación Regional</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={data.work_regional_coordination}
                                            className={classNames({ 'is-invalid': (errors.work_regional_coordination) ? true : false, })}
                                            onChange={e => setData('work_regional_coordination', e.target.value.toUpperCase())} aria-invalid={errors.work_regional_coordination ? "true" : "false"}
                                        />
                                        {errors.work_regional_coordination && <div className="mt-2 text-danger">{errors.work_regional_coordination}</div>}
                                    </Form.Group>
                                </Row>

                                <div className="d-flex gap-3">
                                    <Button onClick={() => setTabkey('step5')} variant="secondary" type="button">Atrás</Button>
                                    <Button variant="primary" disabled={processing} type="submit">Guardar</Button>
                                </div>

                            </Container>
                        </Card>

                    </Tab>
                </Tabs>
            </Form>
        </Row>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Solicitud" />

export default Edit
