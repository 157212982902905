import React, { useState } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { hasPermission } from '../../utils/Permissions';
import { Link, usePage } from '@inertiajs/inertia-react'
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const List = ({ items, location }) => {
    const { flash } = usePage().props
    const data = items.data;

    return (
        <Row>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}

            <span className="p-7">
                <Breadcrumbs currentPath="/admin/master_data/companies" />
            </span>

            <div className="card card-flush h-md-100">
                <div className="card-header pt-7">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">Listado de Empresas</span>
                        <span className="text-gray-400 mt-1 fw-semibold fs-6">{location.nombre}</span>
                    </h3>

                    <div className="card-toolbar">
                        <a href={route('companies.export', location.id)} className='me-3 btn btn-sm btn-secondary'><i className="bi bi-file-earmark-excel"></i> Exportar a Excel</a>
                        {hasPermission('master_data.companies.add') && (
                            <Link href={route('companies.add', location.id)} className='me-3 btn btn-sm btn-primary'>Agregar Empresa</Link>
                        )}
                        <Button href={route('locations.list')} className='btn-sm' variant="secondary" type="normal">Regresar</Button>
                    </div>
                </div>

                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-start">Nombre</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">Descripción</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">Layout TXT</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">Layout XLS</th>
                                    <th className="p-0 pb-3 w-50px text-end"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="3"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.name}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        <p className="text-gray-800 fw-bold mb-1 fs-6">{item.description}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {item.layout_txt === 1 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Si</span>
                                                        )}
                                                        {item.layout_txt === 0 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light">No</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-start flex-column">
                                                        {item.layout_xls === 1 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light-success">Si</span>
                                                        )}
                                                        {item.layout_xls === 0 && (
                                                            <span className="badge py-3 px-4 fs-7 badge-light">No</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    <div className='mx-2'>
                                                        {hasPermission('master_data.companies.edit') && (
                                                            <Link href={route("companies.edit", item.id)}>Editar</Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Empresas" />

export default List
