import { Inertia } from '@inertiajs/inertia'
import React, { useState } from 'react'
import classNames from "classnames";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useForm } from '@inertiajs/inertia-react'
import Layout from '../../Shared/Layout/LayoutAdmin';

const Edit = ({ item }) => {

    const { data, setData, post, processing, errors } = useForm({
        name: item.name || "",
        description: item.description || "",
        layout_txt: item.layout_txt || 0,
        layout_xls: item.layout_xls || 0,
    })

    function submit(e) {
        e.preventDefault()
        post(route('companies.update', item.id))
    }
    return (
        <Row>
            <h2>Editar Empresa</h2>
            <Form onSubmit={submit}>
                <Form.Group className="mb-3" controlId="formAdd">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type="text"
                        className={classNames({
                            'is-invalid': (errors.name) ? true : false,
                        })}
                        value={data.name} onChange={(e) =>
                            setData("name", e.target.value)
                        }
                        aria-invalid={errors.name ? "true" : "false"}
                        disabled
                    />
                    {errors.name && <div className="mt-2 text-danger">{errors.name}</div>}
                </Form.Group>
                <Form.Group className="mb-5 mt-2" controlId="">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control type="text" className={classNames({
                        'is-invalid': (errors.description) ? true : false,
                    })}
                        value={data.description} onChange={e => setData('description', e.target.value)} aria-invalid={errors.description ? "true" : "false"} />
                    {errors.description && <div className="mt-2 text-danger">{errors.description}</div>}
                </Form.Group>
                <Form.Label>Layouts</Form.Label>
                <Form.Group className="mb-5">
                    <Form.Check
                        inline
                        name="layout_txt"
                        type="checkbox"
                        label="Layout TXT"
                        value="1"
                        id="layout_txt"
                        defaultChecked={(data.layout_txt == 1 ? true : false)}
                        onChange={e => { setData('layout_txt', e.target.checked ? 1 : 0) }}
                        className={classNames({ 'is-invalid': (errors.layout_txt) ? true : false, }, 'text-black')}
                        aria-invalid={errors.layout_txt ? "true" : "false"}
                    />
                </Form.Group>
                <Form.Group className="mb-5 text-black ">
                    <Form.Check
                        inline
                        name="layout_xls"
                        type="checkbox"
                        label="Layout XLS"
                        value="1"
                        id="layout_xls"
                        defaultChecked={(data.layout_xls == 1 ? true : false)}
                        onChange={e => { setData('layout_xls', e.target.checked ? 1 : 0) }}
                        className={classNames({ 'is-invalid': (errors.layout_xls) ? true : false }, 'text-black')}
                        aria-invalid={errors.layout_xls ? "true" : "false"}
                    />
                </Form.Group>
                <Button variant="primary" className='me-3' disabled={processing} type="submit">Guardar</Button>
                <Button onClick={() => window.history.back()} variant="secondary" type="normal">Regresar</Button>
            </Form>
        </Row>
    );
}

Edit.layout = page => <Layout children={page} title="Editar Empresa" />

export default Edit
