import "react-datepicker/dist/react-datepicker.css"
import classNames from "classnames"
import AlertDismissible from '../../Shared/Components/AlertDismissible'
import Breadcrumbs from '../../Shared/Components/Breadcrumbs'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Layout from '../../Shared/Layout/LayoutAdmin'
import React, { useRef, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import es from 'date-fns/locale/es'
import { usePage } from '@inertiajs/inertia-react'
import { registerLocale } from "react-datepicker"
import { Link, useForm } from '@inertiajs/inertia-react'
import PreviewTxt from "./Layouts/PreviewTxt"
import PreviewExcel from "./Layouts/PreviewExcel"

registerLocale('es', es)

const List = ({ companies, masters, isPost, years }) => {
    const { flash } = usePage().props
    const [types, setTypes] = useState(['txt', 'xls'])
    const [fortnight, setFortnight] = useState(1)
    const typeLabel = ['', 'Alta', 'Modificación', 'Baja']

    const { data, setData, post, processing, errors, reset } = useForm({
        company_id: companies[0].id,
        company_name: companies[0].name,
        fortnight: 1,
        year: years[0].year,
        type: getFirstType(),
    })
    function handleChangeCompany(e) {
        let company_id = e.target.value
        setData('company_id', company_id)
    }
    function handleChangeFortnight(e) {
        let fortnight = e.target.value
        setData('fortnight', fortnight)
    }
    function handleChangeYear(e) {
        let year = e.target.value
        setData('year', year)
    }
    function handleChangeType(e) {
        let type = e.target.value
        setData('type', type)
    }

    useEffect(() => {
        let company = companies.find(el => el.id == data.company_id)
        getTypes(company)
        setData('company_name', company.name)
    }, [data.company_id])

    function getTypes(company) {
        let options = []
        if (company.layout_txt) options.push('txt')
        if (company.layout_xls) options.push('xls')
        setTypes(options)
    }

    useEffect(() => {
        setData('type', types[0])
    }, [types])

    function getFirstType() {
        let company = companies.find(el => el.id == companies[0].id)
        if (company.layout_txt) return 'txt'
        if (company.layout_xls) return 'xls'
    }

    const PreviewTxt_Ref = useRef()
    const PreviewExcel_Ref = useRef()

    function submit(e) {
        setFortnight(data.fortnight)
        e.preventDefault()
        window.blockUI.block()
        post(route('portfolio_management.add'),
            {
                preserveState: true,
                preserveScroll: true,
                replace: true,
                onError: errors => {
                    Swal.fire({
                        icon: "warning",
                        title: "Oops...",
                        text: "Error de validación",
                    })
                    return
                },
                onFinish: visit => {
                    window.blockUI.release()
                },
            })
    }

    function getLayoutName() {
        if (data.company_id == 12)
            return "INSHIDAL"
        if (data.company_id == 17 || data.company_id == 5)
            return "COBAEH"
        if (data.company_id == 13)
            return "FEDERAL_VERACRUZ"
        if (data.company_id == 14)
            return "ESTATAL_VERACRUZ"
        if (data.company_id == 6)
            return "OPD"
        if (data.company_id == 15)
            return "SNTE31"
        if (data.company_id == 16)
            return "SNTE55"
    }

    function preview() {
        window.blockUI.block()
        axios.get(getGenerateURL() + '&preview=1')
            .then((response) => {
                window.blockUI.release()
                let previewData = response.data
                if (data.type == 'txt') {
                    PreviewTxt_Ref.current.openDialog(previewData)
                } else {
                    PreviewExcel_Ref.current.openDialog(previewData)
                }
            }).catch((error) => {
                window.blockUI.release()
                toastr.error("Algo salió mal, intentalo nuevamente")
            });
    }

    function getGenerateURL() {
        return route('portfolio_management.layout.generate') + '?company_id=' + data.company_id + '&fortnight=' + fortnight + '&company_name=' + data.company_name + '&type=' + data.type + '&year=' + data.year + '&layout=' + getLayoutName()
    }

    return (
        <div>
            <PreviewTxt ref={PreviewTxt_Ref} fortnight={fortnight}></PreviewTxt>
            <PreviewExcel ref={PreviewExcel_Ref} fortnight={fortnight}></PreviewExcel>

            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/portfolio_management" />
            </div>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}
            <Form onSubmit={submit}>
                <Row className="mb-4">
                    <Col sm={4}>
                        <Form.Label className="mb-0">Quincena</Form.Label>
                        <Form.Select defaultValue={1} onChange={handleChangeFortnight} className={classNames({ 'is-invalid': (errors.fortnight) ? true : false, })} aria-invalid={errors.fortnight ? "true" : "false"} >
                            <option value={1}>1era Quincena - 15 Enero</option>
                            <option value={2}>2ª Quincena - 01 Febrero</option>
                            <option value={3}>3ª Quincena - 15 Febrero</option>
                            <option value={4}>4ª Quincena - 01 Marzo</option>
                            <option value={5}>5ª Quincena - 15 Marzo</option>
                            <option value={6}>6ª Quincena - 01 Abril</option>
                            <option value={7}>7ª Quincena - 15 Abril</option>
                            <option value={8}>8ª Quincena - 01 Mayo</option>
                            <option value={9}>9ª Quincena - 15 Mayo</option>
                            <option value={10}>10ª Quincena - 01 Junio</option>
                            <option value={11}>11ª Quincena - 15 Junio</option>
                            <option value={12}>12ª Quincena - 01 Julio</option>
                            <option value={13}>13ª Quincena - 15 Julio</option>
                            <option value={14}>14ª Quincena - 01 Agosto</option>
                            <option value={15}>15ª Quincena - 15 Agosto</option>
                            <option value={16}>16ª Quincena - 01 Septiembre</option>
                            <option value={17}>17ª Quincena - 15 Septiembre</option>
                            <option value={18}>18ª Quincena - 01 Octubre</option>
                            <option value={19}>19ª Quincena - 15 Octubre</option>
                            <option value={20}>20ª Quincena - 01 Noviembre</option>
                            <option value={21}>21ª Quincena - 15 Noviembre</option>
                            <option value={22}>22ª Quincena - 01 Diciembre</option>
                            <option value={23}>23ª Quincena - 15 Diciembre</option>
                            <option value={24}>24ª Quincena - 01 Enero</option>
                        </Form.Select>
                    </Col>
                    <Col sm={3}>
                        <Form.Label className="mb-0">Año</Form.Label>
                        <Form.Select defaultValue={data.year} onChange={handleChangeYear} className={classNames({ 'is-invalid': (errors.year) ? true : false, })} aria-invalid={errors.year ? "true" : "false"} >
                            {years.map((year, index) => {
                                return <option key={index} value={year.year}>{year.year}</option>
                            })}
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="primary" disabled={processing} className='me-3 btn' type="submit" >Obtener Maestros</Button>
                    </Col>
                </Row>
            </Form>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}
            {isPost && (
                <div className="mt-8">
                    {masters.length > 0 && (
                        <div className="text-center mb-4">
                            <Row>
                                <Col>
                                    <Form.Select defaultValue={data.company_id} onChange={handleChangeCompany} className={classNames({ 'is-invalid': (errors.company) ? true : false, })} aria-invalid={errors.company ? "true" : "false"} >
                                        {companies.map((company, index) => {
                                            return <option key={index} value={company.id}>{company.name} - {company.location.nombre}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col md={2}>
                                    <Form.Select value={data.type} onChange={handleChangeType} className={classNames({ 'is-invalid': (errors.type) ? true : false, })} aria-invalid={errors.type ? "true" : "false"} >
                                        {types.map((type, index) => {
                                            return <option key={index} value={type}>{type.toUpperCase()}</option>
                                        })}
                                    </Form.Select>
                                </Col>
                                <Col className="text-left">
                                    <Button variant="primary" onClick={preview} className='me-3 btn btn-primary' type="button" >Prevista de Layout</Button>
                                    <Button variant="secondary" href={getGenerateURL()} className='me-3 btn btn-info' type="button" >Generar de Layout</Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <div className="card card-flush h-md-100">
                        <div className="card-body pt-6">
                            <div className="table-responsive">
                                <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                                    <thead>
                                        <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                            <th className="p-0 pb-3 text-start">Nombre</th>
                                            <th className="p-0 pb-3 text-start">RFC</th>
                                            <th className="p-0 pb-3 text-start">Tipo</th>
                                            <th className="p-0 pb-3 text-start">Monto</th>
                                            <th className="p-0 pb-3 text-end"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {masters.length === 0 && (
                                            <tr>
                                                <td
                                                    className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                                    colSpan="5"
                                                >
                                                    No hay datos.
                                                </td>
                                            </tr>
                                        )}
                                        {masters.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex justify-content-start flex-column">
                                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.name} {item.last_name} {item.mother_last_name}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex justify-content-start flex-column">
                                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{item.rfc}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex justify-content-start flex-column">
                                                                <p className="text-gray-800 fw-bold mb-1 fs-6">{typeLabel[item.type]}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="d-flex justify-content-start flex-column">
                                                                <p className="text-gray-800 fw-bold mb-1 fs-6">$ {item.biweekly_discount}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-around">
                                                            <div className=''>
                                                                <Link href={route("insurance.list") + '?rfc=' + item.rfc}>Ver Pólizas</Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

List.layout = page => <Layout children={page} title="Generar Layout" />

export default List
